<template>
  <v-card
    class="F2gNote"
    @click="emitClick($event)"
    id="cardNote"
    :style="{
      'background-color': backgroundColor,
      'border-radius': borderRadius + 'px',
      color: textColor,
      'font-size': fontSize + 'px',
    }"
  >
    <v-menu top left>
      <template v-slot:activator="{ on }">
        <v-btn class="menuButton ma-1" dark icon small v-on="on">
          <F2gIcon
            value="three-dots"
            :height="14"
            :stroke="textColor"
            :strokeWidth="4"
            :width="14"
          />
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(action, i) in actions"
          :key="i"
          @click="emitActionEvent($event, action)"
        >
          <v-list-item-title>{{ action.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-card-text
      class="pa-0 mt-4"
      id="cardText"
      :style="{
        'background-color': backgroundColor,
        'border-radius': borderRadius + 'px',
        color: textColor,
        'font-size': fontSize + 'px',
      }"
      v-html="text"
    >
    </v-card-text>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "F2gNote",
  props: {
    backgroundColor: {
      default: "#ffffff",
      required: false,
      type: String,
    },
    borderRadius: {
      default: 4,
      required: false,
      type: Number,
    },
    fontSize: {
      default: 16,
      required: false,
      type: Number,
    },
    text: {
      required: false,
      type: String,
    },
    textColor: {
      default: "#202020",
      required: false,
      type: String,
    },
  },
  data() {
    return {
      actions: [
        { alias: "edit", text: this.$t("common.edit") },
        { alias: "delete", text: this.$t("common.delete") },
      ],
    };
  },
  methods: {
    emitActionEvent(event, action) {
      if (action.alias === "edit") this.$emit("edit");
      if (action.alias === "delete") this.$emit("delete");
    },
    emitClick(event) {
      if (event.target.id === "cardNote" || event.target.id === "cardText")
        this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.F2gNote {
  cursor: pointer;
}
.menuButton {
  position: absolute;
  right: 0px;
  top: 0px;
}
</style>
