var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"F2gNote",style:({
    'background-color': _vm.backgroundColor,
    'border-radius': _vm.borderRadius + 'px',
    color: _vm.textColor,
    'font-size': _vm.fontSize + 'px',
  }),attrs:{"id":"cardNote"},on:{"click":function($event){return _vm.emitClick($event)}}},[_c('v-menu',{attrs:{"top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"menuButton ma-1",attrs:{"dark":"","icon":"","small":""}},on),[_c('F2gIcon',{attrs:{"value":"three-dots","height":14,"stroke":_vm.textColor,"strokeWidth":4,"width":14}})],1)]}}])},[_c('v-list',_vm._l((_vm.actions),function(action,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.emitActionEvent($event, action)}}},[_c('v-list-item-title',[_vm._v(_vm._s(action.text))])],1)}),1)],1),_c('v-card-text',{staticClass:"pa-0 mt-4",style:({
      'background-color': _vm.backgroundColor,
      'border-radius': _vm.borderRadius + 'px',
      color: _vm.textColor,
      'font-size': _vm.fontSize + 'px',
    }),attrs:{"id":"cardText"},domProps:{"innerHTML":_vm._s(_vm.text)}}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }